import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fade } from 'react-awesome-reveal';
import { faSquare } from '@fortawesome/pro-solid-svg-icons/faSquare';
import { useRouter } from 'next/router';
import { nextAsset } from '../../../components/nextAsset';
import { SignUpButton } from '../components/SignUpButton';

export const SmartlinksMicroSites: React.FC = () => {
  const intl = useIntl();
  const router = useRouter();

  return (
    <div className="smartlinks-music-marketing py-5">
      <Container className="py-5">
        <Row className="mt-5 justify-content-center align-items-center">
          <Col md={6} className="text-center">
            <Fade direction="left" triggerOnce>
              <img
                src={nextAsset('images/smartLinks/home/smartlink-example-iphone.png')}
                className="img-fluid"
                alt="SmartLinks App webpage example"
                style={{ maxHeight: '90vh' }}
              />
            </Fade>
          </Col>
          <Col md={6}>
            <h2 className="main-header pt-5 pb-3">
              {intl.formatMessage({
                id: 'sml.web.smartlinks.music-marketing.title',
                defaultMessage: 'Hook your audience with actionable micro sites'
              })}
            </h2>

            <ul className="fa-ul pt-5">
              <li className="fs-4 pb-5">
                <span className="fa-li text-secondary">
                  <FontAwesomeIcon icon={faSquare} />
                </span>
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.music-marketing.list.point1',
                  defaultMessage: 'No coding needed'
                })}
              </li>
              <li className="fs-4 pb-5">
                <span className="fa-li text-secondary">
                  <FontAwesomeIcon icon={faSquare} />
                </span>
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.music-marketing.list.point2',
                  defaultMessage: 'Fully customizable'
                })}
              </li>
              <li className="fs-4 pb-5">
                <span className="fa-li text-secondary">
                  <FontAwesomeIcon icon={faSquare} />
                </span>
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.music-marketing.list.point3',
                  defaultMessage: 'Beautiful designs'
                })}
              </li>
              <li className="fs-4 pb-5">
                <span className="fa-li text-secondary">
                  <FontAwesomeIcon icon={faSquare} />
                </span>
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.music-marketing.list.point4',
                  defaultMessage: 'Launch in seconds'
                })}
              </li>
            </ul>
            <SignUpButton
              color="secondary"
              size="lg"
              className="my-1 btn-shadow-secondary"
              source="Index Micro Sites"
              url={router.asPath}
            >
              {intl.formatMessage({
                id: 'sml.web.smart-links.music-marketing.cta',
                defaultMessage: 'Create your free page'
              })}
            </SignUpButton>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
