import React from 'react';
import { NextPage } from 'next';
import Head from 'next/head';
import { useIntl } from 'react-intl';
import { SmartlinksPageTop } from './smartLinks/SmartlinksPageTop';
import { nextAsset } from '../../components/nextAsset';
import { SmartlinksDescription } from './smartLinks/SmartlinksDescription';
import { SmartlinksMicroSites } from './smartLinks/SmartlinksMicroSites';
import { SmartlinksPageExplore } from './smartLinks/SmartlinksPageExplore';
import { Footer } from '../../layouts/Footer';
import { SmartlinksStats } from './smartLinks/SmartlinksStats';
import { SmartlinksStreamingServices } from './smartLinks/SmartlinksStreamingServices';
import { IndexTalkingAboutUs } from './index/IndexTalkingAboutUs';
import { BigCTA } from './smartLinks/BigCTA';
import { SmartlinksMrEaziQuote } from './smartLinks/SmartlinksMrEaziQuote';
import { SmartlinksFeatures } from './smartLinks/SmartlinksFeatures';

/* eslint-disable react/no-danger */
export const IndexPage: NextPage = React.memo(() => {
  const intl = useIntl();
  const title = intl.formatMessage({
    id: 'sml.web.smart-links.title',
    defaultMessage: 'Smart Links for Music Marketing'
  });
  const description = intl.formatMessage({
    id: 'sml.web.smart-links.meta-description',
    defaultMessage: 'Welcome fans from around the world to your music, with links that do more.'
  });
  const promoImage = nextAsset(`images/smartLinks/home/preview.png`, false, { w: 1200 });
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="twitter:title" content={title} key="twitter-title" />
        <meta property="og:title" content={title} key="fb-title" />
        <meta itemProp="description" content={description} key="itemProp-description" />
        <meta name="description" content={description} key="description" />
        <meta property="og:description" content={description} key="fb-description" />
        <meta property="twitter:description" content={description} key="twitter-description" />
        <meta itemProp="image" content={promoImage} key="image" />
        <meta property="twitter:image" content={promoImage} key="twitter-image" />
        <meta property="twitter:image:width" content="1200" key="twitter-image-width" />
        <meta property="twitter:image:height" content="600" key="twitter-image-height" />
        <meta property="og:image" content={promoImage} key="fb-image" />
      </Head>
      <SmartlinksPageTop />
      <SmartlinksDescription />
      <SmartlinksMicroSites />
      <SmartlinksStreamingServices />
      <SmartlinksStats />
      <SmartlinksPageExplore />
      <div className="footer-waves" />
      <SmartlinksFeatures showCTA />
      <SmartlinksMrEaziQuote />
      <div className="smartlink-bg-gradient">
        <IndexTalkingAboutUs />

        <BigCTA source="Index Page" />
      </div>
      <Footer />
    </>
  );
});
