import { GetStaticProps } from 'next';
import { getIntlProps } from '@moxy/next-intl';
import { IndexPage } from '../src/containers/pages/IndexPage';

// eslint-disable-next-line import/no-default-export
export default IndexPage;

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: await getIntlProps(locale)
});
