import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/pro-solid-svg-icons/faSquare';
import { Fade } from 'react-awesome-reveal';
import { useRouter } from 'next/router';
import { nextAsset } from '../../../components/nextAsset';
import { SignUpButton } from '../components/SignUpButton';

export const SmartlinksStats: React.FC = () => {
  const intl = useIntl();
  const router = useRouter();

  return (
    <Container className="py-5">
      <Row className="py-5">
        <Col xs={12} className="d-lg-none">
          <h2 className="text-center main-header pt-5 pb-3">
            {intl.formatMessage({
              id: 'sml.web.smartlinks.stats.title',
              defaultMessage: 'Get crucial audience insights'
            })}
          </h2>
        </Col>
        <Col xs={12} lg={6}>
          <Fade direction="left" triggerOnce>
            <img src={nextAsset('images/smartLinks/home/chart-image.svg')} className="img-fluid" alt="Chart Example" />
          </Fade>
        </Col>
        <Col xs={12} lg={6}>
          <h2 className="main-header pt-5 d-none d-lg-block">
            {intl.formatMessage({
              id: 'sml.web.smartlinks.stats.title',
              defaultMessage: 'Get crucial audience insights'
            })}
          </h2>
          <ul className="fa-ul pt-5">
            <li className="fs-4 pb-5">
              <span className="fa-li text-secondary">
                <FontAwesomeIcon icon={faSquare} />
              </span>
              {intl.formatMessage({
                id: 'sml.web.smartlinks.stats.list.point1',
                defaultMessage: 'Access straightforward metrics'
              })}
            </li>
            <li className="fs-4 pb-5">
              <span className="fa-li text-secondary">
                <FontAwesomeIcon icon={faSquare} />
              </span>
              {intl.formatMessage({
                id: 'sml.web.smartlinks.stats.list.point2',
                defaultMessage: 'Understand your fans'
              })}
            </li>
            <li className="fs-4 pb-5">
              <span className="fa-li text-secondary">
                <FontAwesomeIcon icon={faSquare} />
              </span>
              {intl.formatMessage({
                id: 'sml.web.smartlinks.stats.list.point3',
                defaultMessage: 'Monitor your campaigns'
              })}
            </li>
          </ul>
          <SignUpButton
            color="secondary"
            size="lg"
            className="my-1 btn-shadow-secondary"
            source="Index Stats"
            url={router.asPath}
          >
            {intl.formatMessage({
              id: 'sml.web.smart-links.stats.cta',
              defaultMessage: 'Gain insights'
            })}
          </SignUpButton>
        </Col>
      </Row>
    </Container>
  );
};
