import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { Fade } from 'react-awesome-reveal';
import { nextAsset } from '../../../components/nextAsset';

export const SmartlinksMrEaziQuote: React.FC = () => {
  const intl = useIntl();
  return (
    <div className="py-5">
      <Container className="py-5">
        <Row className="mt-5 justify-content-center align-items-center">
          <Col className="text-center mx-auto" lg={10}>
            <Fade direction="down" triggerOnce>
              <div style={{ maxWidth: 900 }} className="mx-auto">
                <img src={nextAsset('images/smartLinks/home/mreazi-photo.png')} className="img-fluid" alt="Mr Eazi" />
              </div>
            </Fade>
            <h2 className="main-header pt-5 pb-3">
              {intl.formatMessage({
                id: 'sml.web.smartlinks.mreazi.title',
                defaultMessage: '“With Music24, all of my music content is easily accessible in one place.”'
              })}
            </h2>
            <p className="main-header-subtitle text-center">
              {intl.formatMessage({
                id: 'sml.web.smartlinks.mreazi.subtitle',
                defaultMessage: 'Mr Eazi'
              })}
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
