import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useIntl } from 'react-intl';
import { NextSlider } from '../../../components/NextSlider';
import { MediaNews, mediaNews } from './mediaNews';

export const IndexTalkingAboutUsSliderElement: React.FC<{ news: MediaNews }> = React.memo(({ news }) => {
  return (
    <div className="d-flex align-items-center justify-content-center talking-about-us-slider-element px-3 ">
      <div className="d-flex flex-column justify-content-center h-100 w-100">
        <div className="text-white text-center h5 font-weight-normal"> {news.name}</div>
      </div>
    </div>
  );
});

export const IndexTalkingAboutUsSlider: React.FC = React.memo(() => {
  return (
    <NextSlider
      lazyHeight={140}
      arrows={false}
      dots={false}
      centerMode
      // autoplay
      className="center talking-about-us-slider mt-4"
      infinite
      centerPadding="60px"
      slidesToShow={6}
      swipeToSlide
      responsive={[
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 5,
            centerPadding: '100px'
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            centerPadding: '60px'
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3,
            centerPadding: '60px'
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
            centerPadding: '30px'
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            centerPadding: '30px'
          }
        }
      ]}
    >
      {mediaNews.map((news) => (
        <IndexTalkingAboutUsSliderElement news={news} key={news.name} />
      ))}
    </NextSlider>
  );
});

export const IndexTalkingAboutUs: React.FC = () => {
  const intl = useIntl();
  return (
    <div className="talking-about-us py-5 overflow-hidden mw-100">
      <Container className="mt-5">
        <Row>
          <Col>
            <p className="lead text-white text-center pt-5 pb-3">
              {intl.formatMessage(
                {
                  id: 'sml.web.home.talking.about-us',
                  defaultMessage: 'Built specifically for the <b>music industry</b>'
                },
                { b: (str: React.ReactNode[]) => <strong>{str}</strong> }
              )}
            </p>
          </Col>
        </Row>
      </Container>
      <IndexTalkingAboutUsSlider />
    </div>
  );
};
