import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { TopComponent, TopComponentSize } from '../components/TopComponent';
import { HeaderTitle } from '../components/HeaderTitle';
import { PlaylistsSharingGetAccessButton } from '../playlistsSharing/PlaylistsSharingGetAccessButton';
import { MainNavbar } from '../../../layouts/MainNavbar';
import { nextAsset } from '../../../components/nextAsset';

export const SmartlinksPageTop: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <TopComponent size={TopComponentSize.FullWidth} navbarComponent={<MainNavbar />}>
        <Container className="pb-lg-5">
          <Row className="pt-xl-3">
            <Col xs={12} lg={6} className="text-lg-start text-center d-flex flex-column">
              <HeaderTitle>
                {intl.formatMessage({
                  id: 'sml.web.smart-links.main.title-promo',
                  defaultMessage: 'Grow your streams, listeners, and fans'
                })}
              </HeaderTitle>
              <p className="lead">
                {intl.formatMessage(
                  {
                    id: 'sml.web.smart-links.description',
                    defaultMessage: 'Welcome fans from around the world to your music, with links that do more.'
                  },
                  {
                    br: <br />
                  }
                )}
              </p>
              <div className="d-lg-flex d-none justify-content-lg-start">
                <PlaylistsSharingGetAccessButton className="align-items-lg-start" source="SmartLinks Page Top" />
              </div>
            </Col>
            <Col xs={12} lg={6} className="px-0 justify-content-center d-flex flex-column top-promo-img-col">
              <img
                alt="Mr Eazi Screenshot SmartLinks App"
                src={nextAsset('images/smartLinks/home/eazi.png')}
                className="top-promo-img"
              />
              <div className="d-lg-none d-md-block">
                <PlaylistsSharingGetAccessButton source="SmartLinks Page Top" />
              </div>
            </Col>
          </Row>
        </Container>
      </TopComponent>
    </>
  );
};
