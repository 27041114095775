import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/pro-solid-svg-icons/faSquare';
import { useRouter } from 'next/router';
import { nextAsset } from '../../../components/nextAsset';
import { SignUpButton } from '../components/SignUpButton';

export const SmartlinksStreamingServices: React.FC = () => {
  const intl = useIntl();
  const router = useRouter();

  return (
    <div className="bg-sml-light py-5 overflow-hidden">
      <Container className="py-5">
        <Row className="mt-5 justify-content-center align-items-center">
          <Col lg={6} className="justify-content-center d-flex flex-column order-lg-2" style={{ position: 'relative' }}>
            <img
              src={nextAsset('images/smartLinks/home/macbook-smartlink-example-with-stats.png')}
              className="img-fluid"
              alt="SmartLinks App webpage example"
              style={{ maxWidth: '150%', position: 'absolute' }}
            />
          </Col>
          <Col lg={6} className=" order-lg-1">
            <h2 className="main-header text-secondary pt-5 pb-3">
              {intl.formatMessage({
                id: 'sml.web.smartlinks.streaming-services.title',
                defaultMessage: 'Allows fans to listen on their preferred music streaming service'
              })}
            </h2>

            <ul className="fa-ul pt-5">
              <li className="fs-4 pb-5">
                <span className="fa-li text-secondary">
                  <FontAwesomeIcon icon={faSquare} />
                </span>
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.streaming-services.list.point1',
                  defaultMessage: 'Drive more streams on every platform'
                })}
              </li>
              <li className="fs-4 pb-5">
                <span className="fa-li text-secondary">
                  <FontAwesomeIcon icon={faSquare} />
                </span>
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.streaming-services.list.point2',
                  defaultMessage: 'Reduce drop-off'
                })}
              </li>
              <li className="fs-4 pb-5">
                <span className="fa-li text-secondary">
                  <FontAwesomeIcon icon={faSquare} />
                </span>
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.streaming-services.list.point3',
                  defaultMessage: 'Grow a following'
                })}
              </li>
            </ul>
            <SignUpButton
              color="secondary"
              size="lg"
              className="my-1 btn-shadow-secondary"
              source="Index Streaming Services"
              url={router.asPath}
            >
              {intl.formatMessage({
                id: 'sml.web.smart-links.streaming-services.cta',
                defaultMessage: 'Share your music'
              })}
            </SignUpButton>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
