export interface MediaNews {
  name: string;
  testimonial?: string;
}

export const mediaNews: MediaNews[] = [
  {
    name: 'Musicians'
  },
  {
    name: 'Artists'
  },
  {
    name: 'DJs'
  },
  {
    name: 'Bands'
  },
  {
    name: 'Festivals'
  },
  {
    name: 'Labels'
  },
  {
    name: 'Radio stations'
  }
];
