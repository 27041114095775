import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { nextAsset } from '../../../components/nextAsset';

export const SmartlinksDescription: React.FC = () => {
  const intl = useIntl();
  return (
    <div style={{ position: 'relative' }} className="smartlinks-description">
      <Container>
        <Row>
          <Col className="d-flex flex-row py-5">
            <div className="smartlinks-description-rectangle" />
            <div className="py-3">
              <p className="text-white display-5 fw-bold flex-1">
                {intl.formatMessage({
                  id: 'sml.web.smartlinks.description.main1',
                  defaultMessage: 'What are Smart Links?'
                })}
              </p>
              <p className="text-white lead mb-0">
                {intl.formatMessage(
                  {
                    id: 'sml.web.smartlinks.description.main2',
                    defaultMessage:
                      'Smart Links are promotional landing pages designed with a single purpose -{br} to get you more streams and more followers across all streaming platforms. '
                  },
                  {
                    br: <br />
                  }
                )}
              </p>
            </div>
            <div className="bg-waves">
              <img src={nextAsset('images/smartLinks/wave.svg')} alt="Background Waves" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
